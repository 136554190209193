import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '@api/auth.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAuthenticated()) {
      return this.waitForAccountPermissionsLoad().pipe(
        map(() => true),
        catchError(() => of(this.router.createUrlTree(['/login'])))
      );
    }

    return this.router.createUrlTree(['/login']);
  }

  waitForAccountPermissionsLoad() {
    return this.authService.getPermissions(this.authService.getDecoded().id);
  }
}
