import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  AccountModel,
  AccountRole,
  AccountsRequestFilter,
  AccountVerificationStatus,
  CellarAccountsRequestFilter,
} from './models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly baseURL = `${environment.apiUrl}/account`;

  constructor(private httpClient: HttpClient) {}

  createUser(
    email: string,
    password: string
  ): Observable<{ insertedId: number }> {
    const url = `${this.baseURL}`;

    return this.httpClient.post<{ insertedId: number }>(url, {
      email,
      password,
    });
  }

  createModerator(
    role: AccountRole,
    email: string,
    password: string
  ): Observable<{ insertedId: number }> {
    const url = `${this.baseURL}/moderator`;

    return this.httpClient.post<{ insertedId: number }>(url, {
      role,
      email,
      password,
    });
  }

  getById(account_id: number): Observable<AccountModel> {
    const url = `${this.baseURL}/${account_id}`;

    return this.httpClient.get<AccountModel>(url);
  }

  getListByFilter(
    filter: AccountsRequestFilter
  ): Observable<{ count: number; data: AccountModel[] }> {
    const url = `${this.baseURL}/list`;

    let params = new HttpParams()
      .set('limit', filter.limit.toString())
      .set('offset', filter.offset.toString());

    if (filter.role) {
      params = params.set('role', filter.role.toString());
    }

    if (filter.status) {
      params = params.set('status', filter.status);
    }

    if (filter.search_text) {
      params = params.set('search_text', filter.search_text);
    }

    if (filter.verification_status) {
      params = params.set('verification_status', filter.verification_status);
    }

    return this.httpClient.get<{ count: number; data: AccountModel[] }>(url, {
      params,
    });
  }

  getModeratorsListByFilter(
    filter: CellarAccountsRequestFilter
  ): Observable<{ count: number; data: AccountModel[] }> {
    const url = `${this.baseURL}/list-cellar`;

    let params = new HttpParams()
      .set('limit', filter.limit.toString())
      .set('offset', filter.offset.toString());

    if (filter.role) {
      params = params.set('role', filter.role.toString());
    }

    if (filter.search_text) {
      params = params.set('search_text', filter.search_text);
    }

    return this.httpClient.get<{ count: number; data: AccountModel[] }>(url, {
      params,
    });
  }

  verifyEmail(
    account_id: number,
    hash: string
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${account_id}/_verify_email`;

    return this.httpClient.post<{ modifiedId: number }>(url, { hash });
  }

  verifyOrReject(
    account_id: number,
    verification_status: AccountVerificationStatus
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${account_id}/_verify_or_reject`;

    return this.httpClient.post<{ modifiedId: number }>(url, {
      verification_status: verification_status.toLowerCase(),
    });
  }

  updateModerator(
    id: number,
    role: AccountRole,
    email: string
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/moderator/${id}/update`;

    return this.httpClient.put<{ modifiedId: number }>(url, { role, email });
  }

  updateModeratorPassword(
    accountId,
    password: string
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${accountId}/update_password_cellar`;

    return this.httpClient.put<{ modifiedId: number }>(url, { password });
  }

  disable(account_id: number, disabled_reason?: string) {
    const url = `${this.baseURL}/${account_id}/_disable`;
    const body = disabled_reason ? { disabled_reason } : {};

    return this.httpClient.post(url, body);
  }

  enable(account_id: number) {
    const url = `${this.baseURL}/${account_id}/_enable`;

    return this.httpClient.post(url, {});
  }

  recoveryPassword() {
    const url = `${this.baseURL}`;
  }

  referToVerify() {
    const url = `${this.baseURL}`;
  }

  resetVerification(account_id: number) {
    const url = `${this.baseURL}/${account_id}/_reset-verification`;

    return this.httpClient.post<{ modifiedId: number }>(url, {});
  }

  postponeVerification(
    account_id: number,
    data: { reason: string; date: string; }
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${account_id}/_postpone-verification`;

    return this.httpClient.post<{ modifiedId: number }>(url, data);
  }

  resetPassword() {
    const url = `${this.baseURL}`;
  }

  generateToken() {
    const url = `${this.baseURL}`;
  }
}
