<header class="header">
  <h1>Scan QR Code</h1>
  <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
</header>

<!--<div class="select-camera">
  <mat-form-field>
    <mat-select
      [value]="(store.currentDevice$ | async)?.deviceId || null"
      (valueChange)="store.setCurrentDevice($event)"
    >
      <mat-option [value]="null">- not selected -</mat-option>
      <mat-option
        *ngFor="let device of store.availableDevices$ | async"
        [value]="device.deviceId"
      >
        {{ device.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>-->

<zxing-scanner
  #scanner
  [formats]="store.formatsEnabled$ | async"
  [autostart]="true"
  [enable]="true"
  [device]="store.currentDevice$ | async"
  (permissionResponse)="store.setHasPermission($event)"
  (camerasFound)="store.setAvailableDevices($event)"
  (scanSuccess)="store.scanSuccessEffect($event)"
  (torchCompatible)="store.setTorchIsAvailable($event)"
  (deviceChange)="store.setCurrentDevice($event)"
></zxing-scanner>

<div
  class="results"
  *ngIf="store.qrCodeContainedData$ | async as qrCodeContainedData"
>
  <small>Result</small>
  <strong>{{ qrCodeContainedData }}</strong>
</div>
