// Angular specific
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Third party
import { MatDialog } from '@angular/material/dialog';

// App specific
import { LayoutService } from '@app/core/layout';

// Module specific
import { ConfirmDialogComponent } from './confirm-dialog.component';
import {
  ConfirmDialogConfig,
  ConfirmDialogData,
  CONFIRM_DIALOG_DEFAULT_OPTIONS,
} from './confirm-dialog-config';

@Injectable()
export class ConfirmDialog {
  constructor(
    private matDialog: MatDialog,
    private layoutService: LayoutService,
    @Optional()
    @Inject(CONFIRM_DIALOG_DEFAULT_OPTIONS)
    private _defaultOptions: ConfirmDialogConfig
  ) {}

  /**
   * @return true if confirmed, else false
   */
  open(data: ConfirmDialogData): Observable<boolean> {
    return this.matDialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(
        ConfirmDialogComponent,
        {
          closeOnNavigation: true,
          hasBackdrop: true,
          maxWidth: this.layoutService.isMobile() ? '95%' : '500px',
          data: _applyConfigDefaults(data, this._defaultOptions),
        }
      )
      .afterClosed()
      .pipe(map((res) => !!res));
  }
}

function _applyConfigDefaults(
  data?: ConfirmDialogData,
  defaultOptions?: ConfirmDialogConfig
): ConfirmDialogData {
  return { ...defaultOptions, ...data };
}
