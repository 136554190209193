import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { environment } from '@env/environment';

import {
  GetBoughtTicketsCountRes,
  GetOrdersReqFilter,
  OrderModel,
  OrdersHistoryEntityModel,
} from '@api/models';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  private readonly apiBaseUrl: string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = `${environment.apiUrl}/commerce`;
  }

  getOrderById(orderId: number): Observable<OrderModel> {
    const url = `${this.apiBaseUrl}/orders/${orderId}`;

    return this.http.get<OrderModel>(url);
  }

  getOrders(
    filter: GetOrdersReqFilter
  ): Observable<{ data: OrderModel[]; count: number }> {
    const url = `${this.apiBaseUrl}/orders`;
    const params = this.createGetOrdersParams(filter);

    return this.http
      .get<{ data: OrderModel[]; count: number }>(url, { params })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of({ count: 0, data: [] });
          }
          return throwError(err.error);
        })
      );
  }

  getBoughtTicketsCount(
    eventIds: number[]
  ): Observable<Array<GetBoughtTicketsCountRes>> {
    const url = `${this.apiBaseUrl}/count_tickets`;

    return this.http.post<Array<GetBoughtTicketsCountRes>>(url, {
      event_ids: eventIds,
    });
  }

  updateUsedTickets(orderId: number, count: number) {
    const url = `${this.apiBaseUrl}/_update_used_tickets`;

    return this.http
      .post(url, {
        order_id: orderId,
        count,
      })
      .pipe();
  }

  getOrdersHistoryByEventId(
    eventId: number
  ): Observable<OrdersHistoryEntityModel[]> {
    const url = `${this.apiBaseUrl}/orders-history/${eventId}`;

    return this.http
      .get<OrdersHistoryEntityModel[]>(url, {})
      .pipe(catchError(() => of([])));
  }

  private createGetOrdersParams(filter: GetOrdersReqFilter): HttpParams {
    const { limit, offset, event_id, account_id, status } = filter;

    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString());

    if (event_id) {
      params = params.set('event_id', event_id.toString());
    }

    if (account_id) {
      params = params.set('account_id', account_id.toString());
    }

    if (status) {
      params = params.set('status', status);
    }

    return params;
  }
}
