import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/api';

import { IRouteDataPermissionsConfig } from './types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
  permissions$ = this.authService.authAccountPermissions$;

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const config: IRouteDataPermissionsConfig = route.data.permissions;
    return this.handle(config);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const config: IRouteDataPermissionsConfig = childRoute.data.permissions;
    return this.handle(config);
  }

  private handle(config: IRouteDataPermissionsConfig) {
    if (!config) {
      return true;
    }

    return this.authService.hasPermission(config.only).pipe(
      map((isPassed) => {
        if (isPassed) {
          return true;
        }

        return this.router.createUrlTree([config.redirectTo || '/']);
      })
    );
  }
}
