// Angular specific
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

// Third party
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { JWT_OPTIONS, JwtConfig, JwtModule } from '@auth0/angular-jwt';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { IScrollbarOptions } from 'ngx-scrollbar/lib/ng-scrollbar.model';
import {
  ScHorizontalAlignment,
  ScNotifierModule,
  ScVerticalAlignment,
} from '@sceon/notifier';

// App specific
import { QrCodeScannerModule } from '@app/qr-code-scanner';
import { ConfirmDialogModule } from '@app/shared/confirm-dialog';

// Core specific
import { LayoutModule } from './layout';
import { CoreComponent } from './core.component';
import { MATERIAL_DEFAULTS } from './material-defaults';
import { INTERCEPTOR_PROVIDERS } from './interceptors';
import { EnvironmentService } from './services';
import {
  defaultErrorsMap,
  provideErrorMsgHashMap,
} from '@app/shared/form-error-message';

function jwtOptionsFactory(envService: EnvironmentService): JwtConfig {
  const { apiUrl, mediaUrl } = envService.getApiUrlsMap();

  return {
    tokenGetter: () => localStorage.getItem('usertoken'),
    allowedDomains: [new URL(apiUrl).host, new URL(mediaUrl).host],
    disallowedRoutes: [`${apiUrl}/account/token`],
  };
}

@NgModule({
  declarations: [CoreComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    MatSnackBarModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [EnvironmentService],
      },
    }),
    LayoutModule,
    ConfirmDialogModule,
    ScNotifierModule.forRoot(
      {},
      {
        verticalAlignment: ScVerticalAlignment.Bottom,
        horizontalAlignment: ScHorizontalAlignment.Left,
        duration: 4000,
        multiple: false,
        pauseOnHover: true,
        themes: {
          // PRIMARY: { dark: '', light: '' },
          // WARNING: { dark: '', light: '' },
          // SUCCESS: { dark: '', light: '' },
          // DANGER: { dark: '', light: '' },
        },
        animation: { fadeIn: 1000, fadeOut: 1000 },
      }
    ),
    QrCodeScannerModule,
  ],
  exports: [CoreComponent],
  providers: [
    INTERCEPTOR_PROVIDERS,
    MATERIAL_DEFAULTS,
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        autoHeightDisabled: false,
        appearance: 'standard',
        visibility: 'hover',
      } as Partial<IScrollbarOptions>,
    },
    provideErrorMsgHashMap(defaultErrorsMap),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
