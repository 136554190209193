import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EnvironmentTarget } from '@env/target';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  getApiUrlsMap() {
    const { apiUrl, exteriorUrl, mediaUrl } = environment;
    return { apiUrl, exteriorUrl, mediaUrl };
  }

  getTarget(): EnvironmentTarget {
    return environment.target;
  }
}
