import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IfHasPermissionDirective } from './if-has-permission.directive';

@NgModule({
  declarations: [IfHasPermissionDirective],
  imports: [CommonModule],
  exports: [IfHasPermissionDirective],
})
export class PermissionModule {}
