import { Injectable } from '@angular/core';
import {
  AccountStatus,
  AccountVerificationStatus,
  AccountRole,
} from './models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigsService {
  constructor() {}

  getConfigs(): Observable<typeof configs> {
    return of(configs);
  }
}

const configs = {
  email: {
    valid_pattern:
      '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  },
  password: {
    valid_pattern: '^(?=.*[0-9])(?=.*[A-Z])[A-Za-z0-9?!.,*_+-@]{6,15}$',
    min: 6,
    max: 15,
  },
  facebook_url: {
    valid_pattern: '^(https?://)?(www.)?facebook.com/[a-zA-Z0-9(.?)?]',
  },
  instagram_url: {
    valid_pattern: '(https?)?:?(www)?instagram.com/[a-z]*[0-9]*',
  },
  minimum_age: 18,
  statuses: [
    AccountStatus.NotVerified,
    AccountStatus.Active,
    AccountStatus.Disabled,
  ],
  verification_statuses: [
    AccountVerificationStatus.NotVerified,
    AccountVerificationStatus.Pending,
    AccountVerificationStatus.Verified,
    AccountVerificationStatus.Rejected,
  ],
  account_roles: [
    AccountRole.Admin,
    AccountRole.Moderator,
    AccountRole.Sales,
    AccountRole.Customer,
  ],
} as const;
