import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { WINDOW } from '@ng-web-apis/common';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
  MediaMatcher,
} from '@angular/cdk/layout';

import { IRouteDataPermissionsConfig } from '../permissions/types';

export interface NavMenuItem {
  title: string;
  iconKlass: string;
  routerLink: string;
  permissions: IRouteDataPermissionsConfig;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _windowResizeSubscription = Subscription.EMPTY;

  isTablet$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map((state: BreakpointState) => state.matches));

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map((state: BreakpointState) => state.matches));

  navMenuItems: ReadonlyArray<NavMenuItem> = [
    {
      title: 'Dashboard',
      iconKlass: 'home',
      routerLink: '/dashboard',
      permissions: {
        only: [],
      },
    },
    {
      title: 'Events',
      iconKlass: 'event',
      routerLink: '/events',
      permissions: {
        only: [],
      },
    },
    {
      title: 'Accounts',
      iconKlass: 'account_circle',
      routerLink: '/accounts',
      permissions: {
        only: ['account.list.read'],
      },
    },
    {
      title: 'Orders',
      iconKlass: 'savings',
      routerLink: '/orders',
      permissions: {
        only: ['commerce.order.read'],
      },
    },
    {
      title: 'Moderators',
      iconKlass: 'self_improvement',
      routerLink: '/moderators',
      permissions: {
        only: ['account.list-cellar.read'],
      },
    },
    {
      title: 'Statistics',
      iconKlass: 'show_chart',
      routerLink: '/stats',
      permissions: {
        only: ['commerce.orders-history'],
      },
    },
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private mediaMatcher: MediaMatcher,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setWindowResizeSubscription();
  }

  isMobile(): boolean {
    return this.mediaMatcher.matchMedia(Breakpoints.XSmall).matches;
  }

  setWindowResizeSubscription() {
    this._windowResizeSubscription = fromEvent(this.window, 'resize')
      .pipe(debounceTime(150), startWith(new Event('resize')))
      .subscribe((event: Event) => {
        this.setVerticalHeightVariable();
      });
  }

  unsetWindowResizeSubscription() {
    this._windowResizeSubscription.unsubscribe();
  }

  setVerticalHeightVariable(verticalHeight: number | null = null) {
    const vh = verticalHeight ? verticalHeight : window.innerHeight * 0.01;
    this.document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
