import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { environment } from '@env/environment';

import {
  EventModel,
  EventsRequestFilter,
  EventStatus,
  EventType,
  EventBasket,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly baseURL = `${environment.apiUrl}/event`;

  constructor(private httpClient: HttpClient) {}

  getById(event_id: number): Observable<EventModel> {
    const url = `${this.baseURL}/${event_id}`;

    return this.httpClient.get<EventModel>(url).pipe(
      map((event) => ({
        ...event,
        date_start: new Date(event.date_start),
        date_end: new Date(event.date_end),
        publish_date: new Date(event.publish_date),
        date_created: new Date(event.date_created),
        date_last_modified: event.date_last_modified
          ? new Date(event.date_last_modified)
          : null,
        baskets: event.baskets.map((basket) => ({
          ...basket,
          date_from: new Date(basket.date_from),
          date_to: new Date(basket.date_to),
        })),
      }))
    );
  }

  getByFilter(
    filter: EventsRequestFilter
  ): Observable<{ count: number; data: EventModel[] }> {
    const url = `${this.baseURL}/list`;

    let params = new HttpParams()
      .set('limit', filter.limit.toString())
      .set('offset', filter.offset.toString());

    if (filter.account_id) {
      params = params.set('account_id', filter.account_id.toString());
    }

    if (filter.type) {
      params = params.set('type', filter.type);
    }

    if (filter.status) {
      params = params.set('status', filter.status);
    }

    if (filter.date_start) {
      params = params.set('date_start', filter.date_start.toString());
    }

    if (filter.date_end) {
      params = params.set('date_end', filter.date_end.toString());
    }

    return this.httpClient.get<{ count: number; data: EventModel[] }>(url, {
      params,
    });
  }

  getBulk({ ids }: { ids: number[] }): Observable<EventModel[]> {
    const url = `${this.baseURL}/_bulk`;

    return this.httpClient.post<EventModel[]>(url, { ids });
  }

  create(
    model: Partial<EventModel>,
    file: File
  ): Observable<{ insertedId: number }> {
    const formData = new FormData();
    const json: Partial<EventModel> = JSON.parse(JSON.stringify(model)); // deep copy

    // json.baskets = json.baskets.map(
    //   ({ date_from, date_to, price, count }) => ({
    //     date_from,
    //     date_to,
    //     price,
    //     count,
    //   })
    // ) as any;

    json.ticket_limit = json.ticket_limit || 0;

    formData.set('image', file, file.name);
    formData.set('data', JSON.stringify(json));

    return this.httpClient.post<{ insertedId: number }>(this.baseURL, formData);
  }

  update(
    model: Partial<EventModel>,
    file: File | null = null
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${model.id}`;

    const formData = new FormData();

    const json: Partial<EventModel> = JSON.parse(JSON.stringify(model)); // deep copy

    json.ticket_limit = json.ticket_limit || 0;

    // if (json.baskets) {
    //   json.baskets = json.baskets.map(
    //     ({ date_from, date_to, price, count }) => ({
    //       date_from,
    //       date_to,
    //       price,
    //       count,
    //     })
    //   ) as any;
    // }

    if (file) {
      formData.set('image', file, file.name);
      delete json.image_url;
    }

    formData.set('data', JSON.stringify(json));

    return this.httpClient.put<{ modifiedId: number }>(url, formData).pipe();
  }

  delete(event_id: number): Observable<{ event_id }> {
    const url = `${this.baseURL}/${event_id}/delete`;

    return this.httpClient.delete(url).pipe(mapTo({ event_id }));
  }

  enable(event_id: number): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${event_id}/_enable`;

    return this.httpClient.post<{ modifiedId: number }>(url, {}).pipe();
  }

  disable(event_id: number): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${event_id}/_disable`;

    return this.httpClient.post<{ modifiedId: number }>(url, {}).pipe();
  }

  getBaskets(event_id: number): Observable<EventBasket[]> {
    const url = `${this.baseURL}/${event_id}/basket`;

    return this.httpClient.get<EventBasket[]>(url).pipe();
  }

  createBaskets(
    event_id: number,
    baskets: EventBasket[]
  ): Observable<{ inserted_baskets_quantity: number }> {
    const url = `${this.baseURL}/${event_id}/basket/_bulk`;

    return this.httpClient
      .post<{ inserted_baskets_quantity: number }>(url, { baskets })
      .pipe();
  }

  disableOnlineTickets(
    payload: Pick<EventModel, 'id' | 'disable_online_tickets'>
  ): Observable<{ modifiedId: number }> {
    return this.update(payload);
  }
}
