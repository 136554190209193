import { Provider } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
} from '@angular/material/paginator';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
  MatProgressSpinnerDefaultOptions,
} from '@angular/material/progress-spinner';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

export const MATERIAL_DEFAULTS: Provider[] = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
      floatLabel: 'auto',
      hideRequiredMarker: false,
    } as MatFormFieldDefaultOptions,
  },
  {
    provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
    useValue: {
      formFieldAppearance: 'standard',
      pageSizeOptions: [10, 15, 20],
      hidePageSize: false,
      pageSize: 10,
      showFirstLastButtons: true,
    } as MatPaginatorDefaultOptions,
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      closeOnNavigation: true,
      width: '676px',
      hasBackdrop: true,
    } as MatDialogConfig,
  },
  {
    provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
    useValue: {
      _forceAnimations: false,
      diameter: 30,
      strokeWidth: 3,
    } as MatProgressSpinnerDefaultOptions,
  },
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      duration: 3000,
      direction: 'ltr',
      politeness: 'assertive',
    } as MatSnackBarConfig,
  },
];
