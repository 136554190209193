import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestErrorsInterceptor } from './request-errors.interceptor';

export const INTERCEPTOR_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestErrorsInterceptor,
    multi: true,
  },
];
