import { Component, OnInit } from '@angular/core';
import { QrCodeScannerService } from '@app/qr-code-scanner';
import { AuthService } from './api';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {
  appName = 'Cellar';
  authAccount$ = this.authService.authAccount$;

  constructor(
    private authService: AuthService,
    private qrCodeScannerService: QrCodeScannerService
  ) {}

  ngOnInit() {}

  onLogout() {
    this.authService.logout().subscribe();
  }

  onOpenQrCodeScanner() {
    this.qrCodeScannerService.open(null).subscribe();
  }
}
