import { Inject, Injectable } from '@angular/core';
import { NAVIGATOR } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class QrCodeScannerService {
  constructor(@Inject(NAVIGATOR) private navigator: Navigator) {}

  activateVideoCamera() {
    this.navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: 'environment' },
        audio: false,
      })
      .then((mediaStream: MediaStream) => {
        const mediaStreamTrack: MediaStreamTrack = mediaStream.getVideoTracks()[0];
        // setTimeout(() => {
        //   mediaStreamTrack.stop();
        // }, 5000);
      })
      .catch(() => {});
  }
}
