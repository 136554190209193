import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { QrCodeScannerDialogComponentStore } from './qr-code-scanner-dialog-component.store';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

export interface QrCodeScannerDialogComponentData {}
export interface QrCodeScannerDialogComponentResult {}

@Component({
  selector: 'app-qr-code-scanner-dialog',
  templateUrl: './qr-code-scanner-dialog.component.html',
  styleUrls: ['./qr-code-scanner-dialog.component.scss'],
  providers: [QrCodeScannerDialogComponentStore],
})
export class QrCodeScannerDialogComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scanner', { static: true })
  zXingScannerComponent: ZXingScannerComponent;

  constructor(public store: QrCodeScannerDialogComponentStore) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}
}
