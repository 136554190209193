<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.content"></p>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="data.showCancelButton"
    mat-stroked-button
    [mat-dialog-close]="false"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    [mat-dialog-close]="true"
    [color]="data.confirmBtnColor"
  >
    {{ data.confirmBtnText }}
  </button>
</div>
