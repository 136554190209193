import { Injectable } from '@angular/core';
import {
  ComponentStore,
  tapResponse,
  INITIAL_STATE_TOKEN,
} from '@ngrx/component-store';
import { BarcodeFormat } from '@zxing/library';
import {
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { environment } from '@env/environment';
import { CommerceService } from '@app/core/api';
import { Router } from '@angular/router';
import { stateLogger } from '@app/shared/utils';

export interface State {
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo | undefined;
  torchEnabled: boolean;
  formatsEnabled: BarcodeFormat[];
  tryHarder: boolean;
  torchAvailable: boolean;
  hasPermission: boolean;
  qrCodeContainedData: string;
}

export const initialState: State = {
  torchEnabled: false,
  formatsEnabled: [BarcodeFormat.QR_CODE],
  tryHarder: false,
  availableDevices: [],
  currentDevice: null,
  torchAvailable: false,
  hasPermission: false,
  qrCodeContainedData: null,
};

@Injectable()
export class QrCodeScannerDialogComponentStore extends ComponentStore<State> {
  constructor(
    private router: Router,
    private commerceService: CommerceService
  ) {
    super(initialState);
    // this.state$.subscribe((s) => alert(JSON.stringify(s)));
    this.state$.subscribe(stateLogger('QR Code Scanner'));
  }

  ////////////////////////
  // Selectors
  ////////////////////////
  readonly torchEnabled$ = this.select(({ torchEnabled }) => torchEnabled);
  readonly currentDevice$ = this.select(({ currentDevice }) => currentDevice);
  readonly formatsEnabled$ = this.select(
    ({ formatsEnabled }) => formatsEnabled
  );
  readonly tryHarder$ = this.select(({ tryHarder }) => tryHarder);
  readonly availableDevices$ = this.select(
    ({ availableDevices }) => availableDevices
  );
  readonly hasDevices$ = this.select(
    this.availableDevices$,
    (availableDevices) => Boolean(availableDevices && availableDevices.length)
  );
  readonly torchAvailable$ = this.select(
    ({ torchAvailable }) => torchAvailable
  );
  readonly hasPermission$ = this.select(({ hasPermission }) => hasPermission);
  readonly qrCodeContainedData$ = this.select(
    ({ qrCodeContainedData }) => qrCodeContainedData
  );

  ////////////////////////
  // Updaters
  ////////////////////////
  readonly setAvailableDevices = this.updater<MediaDeviceInfo[]>(
    (state, availableDevices) => ({
      ...state,
      availableDevices,
    })
  );

  readonly setTorchIsAvailable = this.updater<boolean>(
    (state, torchAvailable) => ({
      ...state,
      torchAvailable: !!torchAvailable,
    })
  );

  readonly setHasPermission = this.updater<boolean>((state, hasPermission) => ({
    ...state,
    hasPermission: !!hasPermission,
  }));

  readonly setCurrentDevice = this.updater<MediaDeviceInfo>(
    (state, device) => ({
      ...state,
      currentDevice: device || null,
    })
  );

  readonly setQrCodeContainedData = this.updater<string>(
    (state, qrCodeContainedData) => ({
      ...state,
      qrCodeContainedData,
    })
  );

  ////////////////////////
  // Effects
  ////////////////////////
  readonly scanSuccessEffect = this.effect<string>((origin$) =>
    origin$.pipe(
      distinctUntilChanged(),
      map((url) => {
        let orderId;

        try {
          orderId = parseInt(url.split('/').pop().split('?').shift());
        } catch (e) {
          orderId = null;
        }

        return orderId;
      }),
      filter(Boolean),
      exhaustMap((orderId: number) => {
        this.setQrCodeContainedData(orderId.toString());

        return this.commerceService.getOrderById(orderId).pipe(
          tapResponse(
            (order) => {
              this.router.navigate(['/accounts', order.account_id], {
                queryParams: { order_id: order.id, tmp: Date.now() },
              });
            },
            (error) => {
              alert(`Order #${orderId} not found!`);
            }
          )
        );
      })
    )
  );

  readonly permissionResponseEffect = this.effect<boolean>(
    (permissionResponse$) =>
      permissionResponse$.pipe(
        tap((isAllowed: boolean) => {
          this.setHasPermission(isAllowed);
          if (!isAllowed) {
            alert('No permissions for open video camera!');
          }
        })
      )
  );
}
