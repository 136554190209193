import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class MediaService {
  constructor() {}

  getImageUrl(imagePath: string) {
    return `${environment.mediaUrl}${imagePath}`;
  }
}
