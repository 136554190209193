import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { QrCodeScannerDialogComponent } from './qr-code-scanner-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { _MatMenuDirectivesModule } from '@angular/material/menu';

@NgModule({
  declarations: [QrCodeScannerDialogComponent],
  imports: [
    CommonModule,
    ZXingScannerModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    _MatMenuDirectivesModule,
  ],
})
export class QrCodeScannerModule {}
