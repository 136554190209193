import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { ConfirmDialogComponent } from './confirm-dialog.component';
import {
  ConfirmDialogConfig,
  CONFIRM_DIALOG_DEFAULT_OPTIONS,
} from './confirm-dialog-config';
import { ConfirmDialog } from './confirm-dialog.service';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  providers: [
    {
      provide: CONFIRM_DIALOG_DEFAULT_OPTIONS,
      useValue: new ConfirmDialogConfig(),
    },
    ConfirmDialog,
  ],
})
export class ConfirmDialogModule {}
