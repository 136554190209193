import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';

import { AccountModel } from '@api/models';
import { AuthService } from '@app/core/api';
import { QrCodeScannerService } from '@app/core/tickets-receiver';

import { LayoutService, NavMenuItem } from './layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, OnDestroy {
  private readonly _destroyed = new Subject<void>();

  @Input() appName: string;
  @Input() authAccount: AccountModel;
  @Output() logout = new EventEmitter<void>();
  @Output() openQrCodeScanner = new EventEmitter<void>();

  activePageTitle: string;

  mobileQuery: MediaQueryList;

  navList: ReadonlyArray<NavMenuItem>;

  routeChanges$: BehaviorSubject<ActivatedRoute> = new BehaviorSubject(
    this.activatedRoute
  );

  private _mobileQueryListener: () => void;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private media: MediaMatcher,

    private authService: AuthService,
    private layoutService: LayoutService,
    private qrCodeScannerService: QrCodeScannerService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.navList = layoutService.navMenuItems;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroyed)
      )
      .subscribe(() => this.routeChanges$.next(this.activatedRoute));

    this.routeChanges$
      .pipe(
        map((activatedRoute) => {
          let route = activatedRoute;
          const activatedRoutesTitles = [];

          while (route?.firstChild) {
            route = route.firstChild;
            activatedRoutesTitles.push(route.snapshot.data.title);
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        takeUntil(this._destroyed)
      )
      .subscribe((routeData) => {
        this.activePageTitle = routeData.title;
      });
  }

  ngOnDestroy() {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this._destroyed.next();
    this._destroyed.complete();
  }

  onLogout() {
    this.logout.emit();
  }

  onScanQRCode() {
    this.openQrCodeScanner.emit();
  }
}
