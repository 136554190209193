<div class="container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar class="toolbar">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h2 class="app-name">{{ appName }} / {{ activePageTitle }}</h2>

    <button mat-icon-button (click)="onScanQRCode()">
      <mat-icon>qr_code_scanner</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
  >
    <mat-sidenav
      #sidenav
      fixedTopGap="56"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      [opened]="!mobileQuery.matches"
    >
      <mat-nav-list>
        <ng-container *ngFor="let navItem of navList">
          <ng-template [ifHasPermission]="navItem.permissions.only">
            <a
              mat-list-item
              routerLinkActive="active"
              [routerLink]="[navItem.routerLink]"
              (click)="mobileQuery.matches ? sidenav.close() : null"
            >
              <mat-icon mat-list-icon color="primary">
                {{ navItem.iconKlass }}
              </mat-icon>
              {{ navItem.title }}
            </a>
          </ng-template>
        </ng-container>

        <a mat-list-item (click)="onLogout()">
          <mat-icon mat-list-icon color="primary"></mat-icon> Logout
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
