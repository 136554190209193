import { ThemePalette } from '@angular/material/core';
import { InjectionToken } from '@angular/core';

export class ConfirmDialogConfig {
  confirmBtnColor?: ThemePalette = 'primary';
  confirmBtnText?: string | null = 'Confirm';
  showCancelButton?: boolean = true;
}

export interface ConfirmDialogData extends ConfirmDialogConfig {
  title: string;
  content: string;
}

export const CONFIRM_DIALOG_DEFAULT_OPTIONS = new InjectionToken(
  'DEFAULT_CONFIRM_DIALOG_CONFIG_TOKEN'
);
