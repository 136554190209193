import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LayoutService } from '@app/core/layout';
import {
  QrCodeScannerDialogComponent,
  QrCodeScannerDialogComponentData,
  QrCodeScannerDialogComponentResult,
} from './qr-code-scanner-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class QrCodeScannerService {
  constructor(
    private matDialog: MatDialog,
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  open(
    data: QrCodeScannerDialogComponentData
  ): Observable<QrCodeScannerDialogComponentResult | null> {
    const isMobile = this.layoutService.isMobile();

    if (isMobile) {
      this.document.documentElement.classList.add('cdk-global-scrollblock');
    }

    return this.matDialog
      .open<
        QrCodeScannerDialogComponent,
        QrCodeScannerDialogComponentData,
        QrCodeScannerDialogComponentResult
      >(QrCodeScannerDialogComponent, {
        data,
        height: isMobile ? '100%' : null,
        hasBackdrop: true,
      })
      .afterClosed()
      .pipe(
        tap(() => {
          if (isMobile) {
            this.document.documentElement.classList.remove(
              'cdk-global-scrollblock'
            );
          }
        })
      );
  }
}
