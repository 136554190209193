import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '@api/index';
import { map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

type HasPermissionOperator = 'OR' | 'AND';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ifHasPermission]',
})
export class IfHasPermissionDirective implements OnDestroy {
  private _permissions: string | string[];
  private _logicalOperator: HasPermissionOperator = 'AND';
  private _isHidden: boolean = true;
  private _sub: Subscription = Subscription.EMPTY;

  @Input()
  set ifHasPermission(permissions: string | string[]) {
    // console.log({ permissions });
    if (!!permissions) {
      this._permissions = permissions;
      this.updateView();
    }
  }

  @Input()
  set ifHasPermissionOperator(logicalOperator: HasPermissionOperator) {
    // console.log({ logicalOperator });
    if (!!logicalOperator) {
      this._logicalOperator = logicalOperator;
      this.updateView();
    }
  }

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  private updateView() {
    this._sub.unsubscribe();
    this._sub = this.authService
      .hasPermission(this._permissions, this._logicalOperator)
      .pipe(
        tap((hasPermission) => {
          if (hasPermission) {
            if (this._isHidden) {
              this.viewContainer.createEmbeddedView(this.templateRef);
              this._isHidden = false;
            }
          } else {
            this._isHidden = true;
            this.viewContainer.clear();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
