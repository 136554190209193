import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  catchError,
  distinctUntilChanged,
  map,
  pluck,
  tap,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '@env/environment';
import { AccountModel, AccountRole } from './models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseURL = `${environment.apiUrl}/account`;

  readonly authAccountState = new BehaviorSubject<AccountModel | null>(null);
  readonly authAccountPermissionsState = new BehaviorSubject<HashMap<boolean>>(
    {}
  );

  readonly authAccount$ = this.authAccountState.asObservable();
  readonly authAccountPermissions$ = this.authAccountPermissionsState.asObservable();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private jwtHelperService: JwtHelperService
  ) {
    this.setAuthAccount();
    // this.authAccount$.subscribe((auth) => console.log({ auth }));
    // this.authAccountPermissions$.subscribe((perms) => console.log({ perms }));
  }

  private setAuthAccount() {
    this.authAccountState.next(this.getDecoded());
  }

  getDecoded(): AccountModel | null {
    try {
      return this.jwtHelperService.decodeToken<AccountModel>();
    } catch (e) {
      return null;
    }
  }

  isAuthenticated(): boolean {
    try {
      return (
        !this.jwtHelperService.isTokenExpired() &&
        [AccountRole.Admin, AccountRole.Moderator, AccountRole.Sales].includes(
          this.getDecoded().role
        )
      );
    } catch (e) {
      return false;
    }
  }

  login(username: string, password: string): Observable<boolean> {
    const url = `${this.baseURL}/token`;

    return this.httpClient
      .post<{ token: string }>(url, {
        username,
        password,
      })
      .pipe(
        map(({ token }) => {
          try {
            const decoded = this.jwtHelperService.decodeToken(token);

            if (
              decoded &&
              [
                AccountRole.Admin,
                AccountRole.Moderator,
                AccountRole.Sales,
              ].includes(decoded?.role)
            ) {
              localStorage.setItem('usertoken', token);
              this.setAuthAccount();
              return true;
            } else {
              return false;
            }
          } catch (err) {
            return false;
          }
        })
      );
  }

  logout(): Observable<{ isRedirected: boolean }> {
    localStorage.removeItem('usertoken');
    this.setAuthAccount();
    this.authAccountPermissionsState.next({});

    const redirectPromise = this.router
      .navigate(['/login'], {
        state: { navigationReason: '' },
        replaceUrl: true,
      })
      .then((isRedirected) => ({ isRedirected }));

    return from(redirectPromise);
  }

  getPermissions(accountId: number): Observable<string[]> {
    const url = `${this.baseURL}/getAccountPermissions`;
    return this.httpClient
      .post<string[]>(url, { id: accountId })
      .pipe(
        tap((permissions) => {
          this.authAccountPermissionsState.next(
            createHashMapFromPermissions(permissions)
          );
        }),
        catchError((error: HttpErrorResponse) => {
          this.authAccountPermissionsState.next({});
          return of([]);
        })
      );
  }

  hasPermission(
    perm: string | string[],
    op: HasPermissionOperator = 'AND'
  ): Observable<boolean> {
    return combineLatest([
      this.authAccount$.pipe(
        map((acc) => acc?.role),
        distinctUntilChanged()
      ),
      this.authAccountPermissions$,
    ]).pipe(
      map(([authAccountRole, permissions]) => {
        if (!authAccountRole) {
          return false;
        }

        if (authAccountRole === AccountRole.Admin) {
          return true;
        }

        if (typeof perm === 'string') {
          return Boolean(permissions[perm]);
        }

        if (Array.isArray(perm)) {
          if (perm.length === 0) {
            return true;
          }

          if (op === 'AND') {
            return perm.every((p) => Boolean(permissions[p]));
          }
          if (op === 'OR') {
            return perm.some((p) => Boolean(permissions[p]));
          }
        }

        return false;
      })
    );
  }
}

export type HashMap<T = any> = {
  [key: string]: T;
};

type HasPermissionOperator = 'OR' | 'AND';

function createHashMapFromPermissions(perms: string[]): HashMap<boolean> {
  const hashMap = {};

  for (let i = 0, len = perms.length; i < len; i++) {
    hashMap[perms[i]] = true;
  }

  return hashMap;
}
