export interface AccountModel {
  id: number;
  role: AccountRole;
  full_name: string | null;
  email: string;
  status: AccountStatus;
  verification_status: AccountVerificationStatus;
  disabled_reason: string | null;
  date_last_token_generated: Date;
  date_created: Date;
  date_modified: Date;
  date_verified: Date;
  date_of_birth: Date;
  pending_email: null;
  city: null;
  phone: null;
  social_type: AccountSocialLinkType | null;
  social_link: string | null;
  verification_postponed_by: number | null;
  verification_postponed_reason: string | null;
  verification_postponed_until: string | null;
}

export interface AccountsRequestFilter {
  search_text: string;
  role: AccountRole;
  status: AccountStatus;
  verification_status: AccountVerificationStatus;
  offset: number;
  limit: number;
}

export interface CellarAccountsRequestFilter {
  limit: number;
  offset: number;
  role: AccountRole;
  search_text: string;
}

export enum AccountRole {
  Admin = 'admin',
  Moderator = 'moderator',
  Sales = 'sales',
  Customer = 'customer',
}

export enum AccountStatus {
  NotVerified = 'not verified',
  Active = 'active',
  Disabled = 'disabled',
}

export enum AccountVerificationStatus {
  NotVerified = 'not verified',
  Pending = 'pending',
  Verified = 'verified',
  Rejected = 'rejected',
}

export enum AccountSocialLinkType {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Twitter = 'twitter',
}

export interface EventModel {
  id: number;
  type: EventType;
  title_en: string;
  title_am: string;
  description_en: string;
  description_am: string;
  image_url: string;
  on_door_price: number;
  ticket_limit: number;
  status: EventStatus;
  date_start: Date;
  date_end: Date;
  publish_date: Date;
  created_by: number;
  last_modified_by: number;
  date_created: Date;
  date_last_modified: Date;
  disable_online_tickets: boolean;
  current_price: number;
  basket_type: BasketType;
  baskets?: EventBasket[];

  bought_tickets?: number;
  orders_count?: number;
  used_tickets?: number;
}

export enum EventType {
  Private = 'private',
  Public = 'public',
}

export enum EventStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Draft = 'draft',
}

export interface EventsRequestFilter {
  limit: number;
  offset: number;
  account_id: number;
  status: EventStatus;
  type: EventType;
  date_start: Date;
  date_end: Date;
}

export enum BasketType {
  Date = 'date',
  Count = 'count',
  DateOrCount = 'date_or_count'
}

export interface EventBasket {
  id: number;
  event_id: number;
  date_from: Date;
  date_to: Date;
  price: number;
  count: number | null;
}

export interface GetOrdersReqFilter {
  offset: number;
  limit: number;
  account_id?: number;
  event_id?: number;
  status?: OrderStatus;
}

export enum OrderStatus {
  Pending = 'pending',
  Paid = 'paid',
}

export enum OrderCurrency {
  AMD = 'AMD',
}

export interface OrderModel {
  id: number;
  account_id: number;
  event_id: number;
  payment_id: null;
  qr_code_url: string | null;
  used_tickets: number;
  currency: OrderCurrency;
  status: OrderStatus;
  ticket_count: number;
  ticket_price: number;
  total_price: number;
  date_created: Date;
  date_modified: Date | null;
  event?: EventModel;
}

export interface GetBoughtTicketsCountRes {
  event_id: number;
  orders_count: number;
  bought_tickets: number;
  used_tickets: number;
}

export interface OrdersHistoryEntityModel {
  date: string; // "2021-04-06T00:00:00.000Z"
  event_id: number;
  orders_count: number;
  tickets_count: number;
  total_price: number;
}
